
import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AppRouter from "./Router";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={
          <Suspense fallback={<div>Loading..</div>}>
            <AppRouter />
          </Suspense>
        }/>
      </Routes>
    </BrowserRouter>
  );
}