import { Link } from "react-router-dom";

const SpacePage = () => {
  return (
    <div>
        hello world, Space<br/>
        /* QRCODE */
        <Link to='/space/reserve'>
          <div>
            Space Reserve Page
          </div>
        </Link>
    </div>
  );
};

export default SpacePage;