import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const AdminSpacePage = () => {

  const params = useParams();

  const [space, setSpace] = useState<any>(null);

  const fetchSpaceById = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space/"+spaceId)
      .then((res) => {
        setSpace(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // const fetchChangeSpaceStatus = async (spaceId: number, status: string) => {
  //   axios.put("http://localhost:8080/api/change_space_status", {
  //     "spaceId": spaceId,
  //     "status": status
  //   })
  //     .then((res) => {
  //       fetchSpaceListByUserId(adminId);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  useEffect(() => {
    fetchSpaceById(Number(params.spaceId));
    // fetchSpaceListByUserId(adminId);
  }, []);

  return (
    <div>
      hello world, Admin Space Page
      <div>
        title image & edit
      </div>
      <div>
        Wating List
      </div>
      <Link to='qrcode'>
        <div>
          QR Page
        </div>
      </Link>
      <div>Next Customer</div>
      <div>
        Reservation List
      </div>
    </div>
  );
};

export default AdminSpacePage;