import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div>
      hello world, asd
      {/* <Link to='/u/login'>
        <div>
          Login
        </div>
      </Link> */}
      <Link to='space'>
        <div>
          Space
        </div>
      </Link>
      <Link to='admin'>
        <div>
          Admin
        </div>
      </Link>
      <Link to='mypage'>
        <div>
          MyPage
        </div>
      </Link>
    </div>
  );
};

export default HomePage;