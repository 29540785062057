// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDxILzcxmLJFAXKQOSWADRAs78JhveG3kg",
  authDomain: "pick-time-fa1ae.firebaseapp.com",
  projectId: "pick-time-fa1ae",
  storageBucket: "pick-time-fa1ae.appspot.com",
  messagingSenderId: "346560168259",
  appId: "1:346560168259:web:5f2c84cd38c94bb09faff6",
  measurementId: "G-0Q7FBZ58Y8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { auth };