import { Link } from "react-router-dom";

const MyPage = () => {
    return (
      <div>
        hello world, MyPage
        {/* <Link to='/space'>
          <div>
            Space Page
          </div>
        </Link> */}
        <Link to='/admin'>
          <div>
            Admin Page
          </div>
        </Link>
      </div>
    );
  };
  
  export default MyPage;