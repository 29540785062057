import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const AdminSpaceEditPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  console.log(navigate, location, params);

    return (
      <div>asa
        hello world, Admin Space Page
        <p>Space List</p>
        <Link to='/space'>
          <div>
            Space Page
          </div>
        </Link>
        <Link to='/space/admin'>
          <div>
            Space Admin Page
          </div>
        </Link>
      </div>
    );
  };
  
  export default AdminSpaceEditPage;