import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AdminPage = () => {
  const [spaceList, setSpaceList] = useState<[]>([]);
  const adminId = 1;

  const fetchSpaceListByUserId = async (userId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space")
      .then((res) => {
        setSpaceList(res.data.result);

        // let newAdvertisements:Advertisement[] = [];
        // res.data.map((e: any, i: number) => {
        //     let newAdvertisement = {
        //         link: e.url,
        //         image: e.image,
        //         desc: e.title,
        //     }
        //     // console.log(newAdvertisement);
        //     newAdvertisements.push(newAdvertisement);
        // })
        // setAdvertisements(newAdvertisements);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchChangeSpaceStatus = async (spaceId: number, status: string) => {
    axios.put(process.env.REACT_APP_BACKEND_API_URL+"/api/change_space_status", {
      "spaceId": spaceId,
      "status": status
    })
      .then((res) => {
        fetchSpaceListByUserId(adminId);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchSpaceListByUserId(adminId);
  }, []);

  return (
    <div>
      hello world, Admin Space Page
      <p>Space List</p>
      <div>
        {
          spaceList.map((e: any, i: number) => {
            return (
              <div key={'space_'+i} className="flex gap-2">
                {e.id}
                <Link to={'space/'+e.id}>
                  <div>
                    {e.name}
                  </div>
                </Link>
                <div className="flex gap-2">
                  <div>{e.status}</div>
                  <div className="flex gap-2 bg-gray-200">
                    {
                      ["OPEN", "CLOSE", "PAUSE"].map((e2:any, i2:number) => {
                        return (
                          <div onClick={() => fetchChangeSpaceStatus(e.id, e2)}>
                            {e2}
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                <div>alarm</div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
  };
  
  export default AdminPage;