
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages";
import MyPage from "./pages/mypage";
import AdminPage from "./pages/admin";
import SpacePage from "./pages/space";
import AdminSpacePage from "./pages/admin/space";
import AdminSpaceEditPage from "./pages/admin/space/edit";
import AdminSpaceQRCodePage from "./pages/admin/space/qrcode";
import ReservePage from "./pages/reserve";

export default function AppRouter() {
  return (
    <Routes>
        <Route path="" element={<HomePage />}/>
        <Route path="space">
            <Route path="" element={<SpacePage />}/>
        </Route>
        <Route path="admin">
            <Route path="" element={<AdminPage />}/>
            <Route path="space">
                <Route path=":spaceId">
                    <Route path="" element={<AdminSpacePage />}/>
                    <Route path="edit" element={<AdminSpaceEditPage />}/>
                    <Route path="qrcode" element={<AdminSpaceQRCodePage />}/>
                </Route>
            </Route>
        </Route>
        <Route path="mypage">
            <Route path="" element={<MyPage />}/>
        </Route>
        <Route path="reserve">
            <Route path=":Code" element={<ReservePage />}/>
        </Route>
    </Routes>
  );
}